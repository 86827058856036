<template>
  <div class="sf-heading" v-bind="$attrs.attrs">
    <component :is="`h${level}`" class="sf-heading__title" :class="level > 1 && headingClass(level)">
      {{ title }}
    </component>

    <div
      class="sf-heading__description"
      :class="{
        'display-none': hasDescription(description),
      }"
    >
      {{ description }}
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  level: {
    type: Number,
    default: 2,
  },
  title: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
})

const hasDescription = (descriptionProp) => !!descriptionProp
const headingClass = (headingLevel) => (headingLevel <= 6 ? `h${headingLevel}` : `h2`)
</script>

<style lang="scss">
@use './styles/atoms/SfHeading.scss';
</style>
