<template>
  <div class="sf-price" v-bind="$attrs.attrs">
    <slot name="regular" v-bind="{ props }">
      <span class="sf-price__regular" :class="{ 'display-none': !props.regular || props.special }">
        {{ props.regular }}
      </span>
    </slot>
    <slot name="old" v-bind="{ props }">
      <del class="sf-price__old" :class="{ 'display-none': !props.special }">
        {{ props.regular }}
      </del>
    </slot>
    <slot name="special" v-bind="{ props }">
      <ins class="sf-price__special" :class="{ 'display-none': !props.special }">
        {{ props.special }}
      </ins>
    </slot>
  </div>
</template>

<script setup>
const props = defineProps({
  regular: {
    type: [String, Number],
    default: null,
  },
  special: {
    type: [String, Number],
    default: null,
  },
})
</script>

<style lang="scss">
@use './styles/atoms/SfPrice.scss';
</style>
