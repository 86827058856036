<template>
  <div
    class="product-card-labels"
    :class="{ 'ab24041-labels-position-bottom': ab24041 === 'v1' }"
    data-testid="product-card-label"
  >
    <SfBadge v-for="label in labels" :key="label.text" class="product-card__badge" :class="label.class">
      {{ label.text }}
    </SfBadge>
  </div>
</template>

<script setup>
const abTestStore = useAbTestStore()
const { tests } = storeToRefs(abTestStore)
const ab24041 = ref()
const { t } = useI18n()

const props = defineProps({
  product: {
    type: Object,
    default: () => ({}),
  },
  isComingSoon: {
    type: Boolean,
    required: true,
  },
  isSoldOut: {
    type: Boolean,
    required: true,
  },
  hidePlpTags: {
    type: String,
    default: '',
  },
})

const labels = computed(() => {
  const stateLabels = []
  let productLabels = []

  if (props.isComingSoon) {
    stateLabels.push({
      text: t('COMING SOON'),
      class: 'sf-badge--coming-soon',
    })
  } else if (props.isSoldOut) {
    stateLabels.push({
      text: t('BACK SOON'),
      class: 'sf-badge--back-soon',
    })
  }

  if (props.product.labels?.length) {
    productLabels.push(
      ...(props.product.labels ?? []).map((label) => {
        return {
          text: label,
          class: 'sf-badge--regular',
        }
      }),
    )

    // Show maximum of two labels
    if (stateLabels.length) {
      productLabels = productLabels.slice(0, 1)
    } else {
      productLabels = productLabels.slice(0, 2)
    }
  }

  return [...productLabels, ...stateLabels]
})

onMounted(() => {
  ab24041.value = tests?.value?.get(24041)
})
</script>

<style lang="scss" scoped>
html.theme--storefront {
  .product-card-labels {
    position: absolute;
    top: var(--product-card-badge-top, var(--spacer-xs));
    left: var(--product-card-badge-top, var(--spacer-xs));
    display: flex;
    flex-direction: column;
    gap: var(--spacer-2xs);

    &.ab24041-labels-position-bottom {
      top: unset;
      bottom: var(--product-card-badge-top, var(--spacer-xs));
      &:not(:empty) ~ :deep(.product-card-rating) {
        display: none;
      }
    }

    .sf-badge {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 26px;
      padding: 0 var(--spacer-2xs);
      width: max-content;
      background: var(--white-color);
      color: var(--black-color);
      border-radius: 4px;
      box-shadow: 1px 1px 2px 0px #0000001a;
      font-size: var(--font-size-10);
      font-weight: 400;

      @include for-desktop {
        padding: 0 var(--spacer-xs);
        font-size: var(--font-size-13);
        min-height: 34px;
      }

      &--back-soon,
      &--coming-soon {
        background: var(--gray-secondary-color);
        color: var(--white-color);
      }

      &--regular {
        text-transform: none;
      }
    }
  }
}
</style>
