<template>
  <div v-if="rating" class="product-card-rating" data-testid="product-card-rating">
    <SfRating
      class="product__average-rating"
      :score="rating"
      :max="5"
      :icon="starIconPath"
      data-testid="product-card-rating__average-rating"
    />
    <span class="product-card-rating__count" data-testid="product-card-rating__count">
      {{ count }} {{ $t(count === 1 ? 'Review' : 'Reviews') }}
    </span>
  </div>
</template>

<script setup lang="ts">
defineProps({
  rating: {
    type: Number,
    default: 0,
  },
  count: {
    type: Number,
    default: 0,
  },
})

const starIconPath = 'M11.963,0l2.732,8.407h8.84l-7.152,5.2,2.732,8.407-7.152-5.2-7.152,5.2L7.543,13.6.392,8.407h8.84Z'
</script>

<style lang="scss">
html.theme--storefront {
  .product-card-rating {
    pointer-events: none;
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    border-radius: 4px;
    background: var(--c-white);
    font-size: 8px;

    @include for-desktop {
      bottom: 12px;
      gap: 8px;
      font-size: 12px;
    }

    @include for-mobile {
      .sf-rating__icon {
        --icon-size: 10px !important;
      }
    }

    &__count {
      width: max-content;
    }
  }
}
</style>
