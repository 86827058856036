<template>
  <NuxtLink v-focus class="sf-link" :to="link">
    <slot />
  </NuxtLink>
</template>
<script>
import { focus } from './utilities/directives'
export default {
  name: 'SfLink',
  directives: { focus },
  props: {
    link: {
      type: [String, Object],
      default: '',
    },
  },
}
</script>
<style lang="scss">
@use './styles/atoms/SfLink.scss';
</style>
