<template>
  <component
    :is="linkComponent"
    :class="[
      'sf-button',
      {
        'is-disabled--button': buttonActive(link, disabled),
        'is-disabled--link': linkActive(link, disabled),
      },
    ]"
    :style="[$attrs.style, $attrs.staticStyle]"
    :aria-disabled="disabled"
    :link="link"
    :disabled="disabled ? true : null"
    v-bind="$attrs.attrs"
  >
    <slot />
  </component>
</template>

<script>
import { focus } from './utilities/directives'
import SfLink from './Link.vue'

export default defineComponent({
  name: 'SfButton',
  directives: {
    focus,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    link: {
      type: [String, Object],
      default: null,
    },
  },
  methods: {
    linkActive(link, disabled) {
      return link && disabled
    },
    buttonActive(link, disabled) {
      return !link && disabled
    },
  },
  computed: {
    linkComponent() {
      return this.link ? SfLink : 'button'
    },
  },
})
</script>
<style lang="scss">
@use './styles/atoms/SfButton.scss';
</style>
