<template>
  <component :is="props.tag" v-if="props.content" v-dompurify-html:iframe="props.content" />
</template>

<script setup lang="ts">
const props = defineProps({
  tag: {
    type: String,
    default: 'div',
  },
  content: {
    type: String,
    default: '',
  },
})
</script>

<style lang="scss">
html.theme--storefront ul {
  list-style-type: disc;
}
</style>
